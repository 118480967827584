var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "error-container",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn pb-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { sm: "12", md: "12", lg: "12", xl: "12" } },
                [
                  _c(
                    "h1",
                    {
                      staticClass: "px-0",
                      class: [
                        `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                        { "is-pwa": _vm.$isPwa() },
                      ],
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t\t" +
                          _vm._s(_vm.FormMSG(215, "Purchase Order List")) +
                          "\n\t\t\t\t"
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container-layout details-document-package p-0" },
            [
              _c(
                "b-card",
                {
                  staticClass: "card-border-blue-light pb-4",
                  attrs: { "no-body": "" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "back-with-title cursor-pointer py-1",
                      class: { "mt-16": _vm.$isPwa() },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "icon",
                          on: { click: _vm.handleClickBack },
                        },
                        [
                          _c("ArrowLeft", {
                            staticClass: "icon",
                            attrs: { color: "rgba(6, 38, 62, 0.65)", size: 22 },
                          }),
                        ],
                        1
                      ),
                      _c("h2", [_vm._v(_vm._s(_vm.titlePage))]),
                    ]
                  ),
                  _c("div", { staticClass: "form pt-3 px-1" }, [
                    _c(
                      "div",
                      { staticClass: "row mt-3 hide-on-tablet" },
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "5" } },
                          [
                            _c(
                              "b-form-group",
                              { staticClass: "mb-0" },
                              [
                                _vm.$screen.width >= 992
                                  ? _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            type: "text",
                                            id: "filterInput",
                                            placeholder: this.FormMSG(
                                              37,
                                              "Type to Search"
                                            ),
                                          },
                                          model: {
                                            value: _vm.filter,
                                            callback: function ($$v) {
                                              _vm.filter = $$v
                                            },
                                            expression: "filter",
                                          },
                                        }),
                                        _c(
                                          "b-input-group-append",
                                          { staticClass: "cursor-pointer" },
                                          [
                                            _c(
                                              "b-input-group-text",
                                              { staticClass: "btn-search" },
                                              [
                                                _vm.filter.length === 0
                                                  ? _c("Search", {
                                                      staticClass: "icon",
                                                      attrs: {
                                                        color: "#FFFFFF",
                                                        size: 16,
                                                        "stroke-width": 2.5,
                                                      },
                                                    })
                                                  : _c("X", {
                                                      staticClass: "icon",
                                                      attrs: {
                                                        color: "#FFFFFF",
                                                        size: 16,
                                                        "stroke-width": 2.5,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.filter = ""
                                                        },
                                                      },
                                                    }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "row mt-3 ml-1" },
                      [
                        !_vm.$screen.md
                          ? _c("CardListBuilder", {
                              staticClass: "hide-on-desktop",
                              staticStyle: { width: "100%" },
                              attrs: {
                                items: _vm.purchaseOrders,
                                filter: _vm.filter,
                                fields: _vm.bgFieldsMobile,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function (data) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            attrs: { role: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openModal(data.item)
                                              },
                                            },
                                          },
                                          [_c("edit")],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3929413293
                              ),
                            })
                          : _c("b-table", {
                              staticStyle: { width: "100%" },
                              attrs: {
                                responsive: "sm",
                                filter: _vm.filter,
                                items: _vm.purchaseOrders,
                                fields: _vm.bgFields,
                                "sticky-header": "700px",
                                "head-variant": _vm.hv,
                                bordered: "",
                                small: "",
                                "show-empty": "",
                                "empty-text": _vm.FormMSG(
                                  289,
                                  "No purchase orders found"
                                ),
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "cell(validated)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "wrap-status" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              class: `status ${data.item.statusClass}`,
                                              staticStyle: {
                                                "font-size": "0.7rem",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    data.item.validatedStatus
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(departmentName)",
                                  fn: function (data) {
                                    return [
                                      _c("div", { staticClass: "text-left" }, [
                                        _c("div", [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                data.item.departmentName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  data.item.departmentName
                                                    .slice(1)
                                                    .toLowerCase()
                                              ) +
                                              " -\n\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(
                                                data.item.functionName
                                                  .charAt(0)
                                                  .toUpperCase() +
                                                  data.item.functionName
                                                    .slice(1)
                                                    .toLowerCase()
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  },
                                },
                                {
                                  key: "cell(option)",
                                  fn: function (data) {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          attrs: { role: "button" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToDetail(data.item)
                                            },
                                          },
                                        },
                                        [_c("eye", { attrs: { size: 16 } })],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }