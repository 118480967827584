<template>
	<error-container :error="erreur" :warning="warning">
		<div class="animated fadeIn pb-2">
			<b-row>
				<b-col sm="12" md="12" lg="12" xl="12">
					<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]" class="px-0">
						{{ FormMSG(215, 'Purchase Order List') }}
					</h1>
				</b-col>
			</b-row>
			<div class="container-layout details-document-package p-0">
				<b-card no-body class="card-border-blue-light pb-4">
					<div class="back-with-title cursor-pointer py-1" :class="{ 'mt-16': $isPwa() }">
						<div class="icon" @click="handleClickBack">
							<ArrowLeft color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
						</div>
						<h2>{{ titlePage }}</h2>
					</div>
					<div class="form pt-3 px-1">
						<div class="row mt-3 hide-on-tablet">
							<b-col md="5">
								<b-form-group class="mb-0">
									<b-input-group v-if="$screen.width >= 992">
										<b-form-input v-model="filter" type="text" id="filterInput" :placeholder="this.FormMSG(37, 'Type to Search')" />
										<b-input-group-append class="cursor-pointer">
											<b-input-group-text class="btn-search">
												<Search color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" v-if="filter.length === 0" />
												<X color="#FFFFFF" :size="16" class="icon" :stroke-width="2.5" @click="filter = ''" v-else />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</b-form-group>
							</b-col>
						</div>
						<div class="row mt-3 ml-1">
							<CardListBuilder
								class="hide-on-desktop"
								v-if="!$screen.md"
								:items="purchaseOrders"
								:filter="filter"
								style="width: 100%"
								:fields="bgFieldsMobile"
							>
								<template slot="actions" slot-scope="data">
									<div @click="openModal(data.item)" role="button">
										<edit />
									</div>
								</template>
							</CardListBuilder>
							<b-table
								responsive="sm"
								:filter="filter"
								v-else
								:items="purchaseOrders"
								:fields="bgFields"
								style="width: 100%"
								sticky-header="700px"
								:head-variant="hv"
								bordered
								small
								show-empty
								:empty-text="FormMSG(289, 'No purchase orders found')"
							>
								<template #cell(validated)="data">
									<div class="wrap-status">
										<div :class="`status ${data.item.statusClass}`" style="font-size: 0.7rem">
											{{ data.item.validatedStatus }}
										</div>
									</div>
								</template>
								<template #cell(departmentName)="data">
									<div class="text-left">
										<div>
											{{ data.item.departmentName.charAt(0).toUpperCase() + data.item.departmentName.slice(1).toLowerCase() }} -
											{{ data.item.functionName.charAt(0).toUpperCase() + data.item.functionName.slice(1).toLowerCase() }}
										</div>
									</div>
								</template>
								<template #cell(option)="data">
									<div @click="goToDetail(data.item)" role="button">
										<eye :size="16" />
									</div>
								</template>
							</b-table>
						</div>
					</div>
				</b-card>
			</div>
		</div>
	</error-container>
</template>

<script>
import { ArrowLeft, Edit, Eye, Search, X } from 'lucide-vue';
import languageMessages from '@/mixins/languageMessages';
// import { getTextFromMenuNumberAndMenuValue } from '@/cruds/language.crud';
import { store } from '@/store';
import { getProjectUserPoDetail } from '@/cruds/budget.crud';
import { rendCurrency, rendKgCo2 } from '~helpers';
import moment from 'moment';

// const validatedText = async (validated) => {
// 	let value = null;
// 	await new Promise((resolve, reject) => {
// 		resolve(getTextFromMenuNumberAndMenuValue(1008, validated));
// 		reject(false);
// 	}).then((data) => {
// 		value = data;
// 	});

// 	return value;
// };
const validatedColor = (validated) => {
	let retval = 'not-submitted';
	if (validated == 1) {
		retval = 'info';
	} else if (validated == 2) {
		retval = 'pending';
	} else if (validated == 8) {
		retval = 'validated';
	} else if (validated == 4 || validated == 16) {
		retval = 'refused';
	}
	return retval;
};

export default {
	name: 'BudgetPOList',

	components: {
		ArrowLeft,
		Edit,
		Eye,
		Search,
		X
	},

	mixins: [languageMessages],

	data() {
		return {
			erreur: {},
			warning: '',
			hv: 'dark',
			filter: '',
			curBudgetDataDepCat: {},
			purchaseOrders: []
		};
	},

	computed: {
		titlePage() {
			return `Purchase orders for department: ${this.curBudgetDataDepCat.departmentName} / ${this.curBudgetDataDepCat.name}`;
		},
		bgFields() {
			return [
				{
					key: 'requestDate',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'id',
					label: this.FormMSG(200, 'ID'),
					formatter: (value) => {
						return '#' + value;
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'description',
					label: this.FormMSG(211, 'Description'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplierName',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'departmentName',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validated',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amountTotal',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'option',
					label: this.FormMSG(120, 'Option'),
					class: 'text-center'
				}
			];
		},
		bgFieldsMobile() {
			return [
				{
					key: 'poDate',
					label: this.FormMSG(17, 'Date'),
					formatter: (value) => {
						return moment(value).format('DD/MM/YYYY');
					},
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'description',
					label: this.FormMSG(200, 'Descritpion'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'category',
					label: this.FormMSG(211, 'Category'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'supplier',
					label: this.FormMSG(221, 'Supplier'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'fullName',
					label: this.FormMSG(231, 'Full Name'),
					formatter: (v) => v.charAt(0).toUpperCase() + v.slice(1).toLowerCase(),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'department',
					label: this.FormMSG(241, 'Department & Function'),
					sortable: true,
					class: 'text-left'
				},
				{
					key: 'validatedStatus',
					label: this.FormMSG(24, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'amount',
					label: this.FormMSG(25, 'Amount'),
					formatter: (value) => {
						return this.displayCurrency(value);
					},
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'co2',
					label: 'CO2',
					formatter: (value) => {
						return this.displayKgCo2(value);
					},
					class: 'text-right',
					sortable: true
				}
			];
		}
	},

	async created() {
		// await this.$apollo.getClient().resetStore()
		this.getInfoPOFromStore();
		await this.getBudgetPOData();
	},

	methods: {
		validatedText(validated) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, validated);
		},
		getInfoPOFromStore() {
			this.curBudgetDataDepCat = store.getCurBudgetDataForDepartmentCategory();
		},
		handleClickBack() {
			this.$router.push({
				path: `/budget?search=${this.$route.query.search}`
			});
		},
		goToDetail(item) {
			store.setCurPO(item);
			this.$router.push({ path: `/budget/purchase-order/${item.id.toString()}?PO=2` });
		},
		async getBudgetPOData() {
			let newFilterActive = {
				category: parseInt(this.$route.params.categoryId, 10),
				inProgressStatus: true
			};

			const data = await getProjectUserPoDetail({ ...newFilterActive, groupBySheets: true }, 'no-cache');

			let arr = [];
			for (const item of data) {
				let PO = item.purchaseOrder;
				PO.fullName = PO.user.name + ' ' + PO.user.firstName;
				PO.statusClass = validatedColor(PO.validated);
				PO.validatedStatus = this.validatedText(PO.validated);
				arr.push(PO);
			}

			this.purchaseOrders = arr;
		},
		displayCurrency(value) {
			return rendCurrency(value);
		},
		displayKgCo2(value) {
			return rendKgCo2(value);
		}
	}
};
</script>
